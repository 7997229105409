var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-center mb-5"},[_c('h2',{staticClass:"my-5"},[(_vm.settings.logo)?_c('strong',[_vm._v(_vm._s(_vm.$t("login.bienvenue"))+" "+_vm._s(_vm.settings.logo))]):_c('strong',[_vm._v(_vm._s(_vm.$t("login.title"))+" ")])])]),_c('div',{staticClass:"card mb-0",class:_vm.$style.container,staticStyle:{"width":"100%","margin":"auto"}},[_c('div',{staticClass:"text-dark font-size-24 mb-3 mt-0"},[_c('strong',[_vm._v(_vm._s(_vm.$t("login.connectezVous")))])]),_c('p',{staticClass:"mt-0 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("login.subscribePart1"))+" "),_c('a',{staticClass:"terms-link",attrs:{"href":"https://edtrust.tn/subscribe.php","target":"_blank"}},[_c('b',[_vm._v(_vm._s(_vm.$t("login.subscribePart2")))])]),_vm._v(" "+_vm._s(_vm.$t("login.subscribePart3"))+" ")]),_c('div',{staticClass:"mb-4"}),_c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'userName',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('login.requisUsername'),
                } ],
            } ]),expression:"[\n            'userName',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('login.requisUsername'),\n                },\n              ],\n            },\n          ]"}],attrs:{"size":"large","placeholder":_vm.$t('login.username')}})],1),_c('a-form-item',{staticClass:"mb-3"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('login.requisMdp'),
                } ],
            } ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('login.requisMdp'),\n                },\n              ],\n            },\n          ]"}],attrs:{"size":"large","placeholder":_vm.$t('profil.mdp'),"type":"password"}})],1),_c('a-checkbox',{staticClass:"mb-2 mt-0",on:{"change":function($event){_vm.acceptedTerms = !_vm.acceptedTerms}}},[_vm._v(" "+_vm._s(_vm.$t("login.readAndAccept"))+" "),_c('a',{staticClass:"terms-link",attrs:{"href":_vm.termsLink,"target":"_blank"}},[_c('b',[_vm._v(_vm._s(_vm.$t("login.termsAndConditions"))+".")])])]),_c('a-button',{staticClass:"text-center w-100",attrs:{"type":_vm.acceptedTerms ? 'primary' : '',"htmlType":"submit","size":"large","disabled":!_vm.acceptedTerms,"loading":_vm.loading}},[_c('strong',[_vm._v(_vm._s(_vm.$t("login.connextion")))])])],1),_c('a-dropdown',{staticStyle:{"margin":"auto"},attrs:{"trigger":['click'],"placement":"bottomCenter"}},[_c('div',{class:_vm.$style.dropdown},[_c('button',{staticClass:"btn btn-light dropdown-toggle dropdown-toggle-noarrow",attrs:{"type":"button"}},[_c('i',{staticClass:"fe fe-more-horizontal"}),_c('img',{staticStyle:{"border-radius":"25%"},attrs:{"width":"20","src":("resources/images/flags/" + (_vm.language == 'en'
                ? 'united-states'
                : _vm.language == 'fr'
                ? 'france'
                : 'tunisia') + ".svg"),"alt":"Arabic"}}),_vm._v(" "+_vm._s(_vm.$t("language." + _vm.language))+" ")])]),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":_vm.selectLocale},slot:"overlay"},[_c('a-menu-item',{key:"en-US"},[_c('img',{staticStyle:{"border-radius":"25%"},attrs:{"width":"20","src":"resources/images/flags/united-states.svg","alt":"Arabic"}}),_vm._v(" "+_vm._s(_vm.$t("language.en"))+" ")]),_c('a-menu-item',{key:"fr-FR"},[_c('img',{staticStyle:{"border-radius":"25%"},attrs:{"width":"20","src":"resources/images/flags/france.svg","alt":"Arabic"}}),_vm._v(" "+_vm._s(_vm.$t("language.fr"))+" ")]),_c('a-menu-item',{key:"ar-AR"},[_c('img',{staticStyle:{"border-radius":"30%"},attrs:{"width":"20","src":"resources/images/flags/tunisia.svg","alt":"Arabic"}}),_vm._v(" "+_vm._s(_vm.$t("language.ar"))+" ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }